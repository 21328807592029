<style>
    .title {
        font-family: Source Han Sans SC;
        font-weight: 400;
        font-size: 20px;
        color: #265599;
    }

    .ltitle {
        font-weight: 500;
        font-size: 20px;
        color: #666666;
        margin-top: 41px;
        text-align: left;
        width: 250px;
    }

    .linput {
        width: 280px;
        display: flex;
        align-items: center;
    }

    .bt {
        color: red;
        margin-left: 10px;
    }

    .item {
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        font-size: 12px;
        color: rgba(38, 85, 153, 0.85);
        margin-top: 17px;
    }

    .subtn {
        background-color: #265599;
        width: 280px;
        color: white;
        padding: 13px 120px;
        font-size: 16px;
        margin-top: 30px;
        border-radius: 5px;
        margin-bottom: 30px;
    }

    .el-form-item__content {
        display: flex;
    }


    .input {
        height: 48px !important;
        width: 268px !important;
    }
</style>
<template>

    <div v-if="wxShow" style="display: flex;flex-direction:column;align-items: center;justify-content: center;">
        <div style="margin-top: 16px">
            <img src="../assets/aboutright.png" style="min-width: 220px;min-height: 156px;width: 220px"/>
        </div>

        <div class="title">陕西省首届密码技术竞赛</div>
        <div style="margin-top: 20px">
            <el-form :model="login_data" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">

                <el-form-item label="" prop="name" class="linput">
                    <el-input class="input" maxlength="10" prefix-icon="el-icon-user" placeholder="请输入姓名"
                              v-model="login_data.name"
                              clearable>

                    </el-input>
                    <div class="bt">*</div>
                </el-form-item>

                <el-form-item label="" prop="phone" class="linput">
                    <el-input class="input" maxlength="11" prefix-icon="el-icon-user" placeholder="请输入手机号"
                              v-model="login_data.phone"
                              clearable>

                    </el-input>
                    <div class="bt">*</div>
                </el-form-item>


                <el-form-item label="" prop="id_card" class="linput">
                    <el-input class="input" maxlength="18" prefix-icon="el-icon-postcard" placeholder="请输入身份证"
                              v-model="login_data.id_card" clearable>
                    </el-input>
                    <div class="bt">*</div>
                </el-form-item>


                <el-form-item label="" prop="type" class="linput">
                    <el-select class="input" style="width: 263px" v-model="login_data.type" placeholder="请选择考试赛道">
                        <el-option value="专业技术" label="专业技术"/>
                        <el-option value="密码管理" label="密码管理"/>
                        <el-option value="青年红色" label="青年红色"/>
                    </el-select>
                    <div class="bt">*</div>
                </el-form-item>

                <!--<div class="linput">
                    <el-input maxlength="10" prefix-icon="el-icon-thumb" placeholder="请输入指导老师" v-model="login_data.teacher"
                    ></el-input>
                    <div class="bt">*</div>
                </div>-->
                <el-form-item label="" prop="pwd" class="linput">
                    <el-input class="input" minlength="6" prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="login_data.pwd"
                              show-password></el-input>
                    <div class="bt">*</div>
                </el-form-item>
                <el-form-item label="" prop="rpwd" class="linput">
                    <el-input class="input" prefix-icon="el-icon-lock" placeholder="请再次确认密码" v-model="login_data.rpwd"
                              show-password></el-input>
                    <div class="bt">*</div>
                </el-form-item>
                <el-form-item>
                    <el-button style="background-color:#265599 " class="subtn" type="primary"
                               @click="login('ruleForm')">提交
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
    <div v-else>
        <img src="../assets/wbg.jpg" style="width: 100vw;"/>
    </div>
</template>
<script>
    import request from "../plugins/axios.js";

    export default {
        data() {
            let that = this
            //姓名
            let checkName = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('姓名不能为空'));
                }

                let name = /^[\u4e00-\u9fa5·]+$/;
                if (!name.test(value)) {
                    callback(new Error('请输入正确的姓名'));
                }

                if (value.length < 2 || value.length > 16) {
                    callback(new Error('请输入正确的姓名'));
                }
                callback();
            };
            //手机号
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入手机号'));
                }

                let phone = /^1{1}[0-9]{10}$/;
                if (!phone.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                }
                request({
                    url: "/mn/checkcp",
                    data: {keyword: value},
                }).then((ret) => {

                    if (ret.code == 500) {
                        callback(new Error('手机号已注册'));
                    }
                    callback();
                })

            };

            //身份证
            let checkIDCard = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入身份证号'));
                }
                //61032219901015645X
                let id_card = /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[Xx\d]$/;
                if (!id_card.test(value)) {
                    callback(new Error('请输入正确的身份证'));
                }
                request({
                    url: "/mn/checkcp",
                    data: {keyword: value},
                }).then((ret) => {

                    if (ret.code == 500) {
                        callback(new Error('身份证号已注册'));
                    }
                    callback();
                })
            };

            //密码
            let checkPwd = (rule, value, callback) => {
                if (!value) {
                    callback(new Error('请输入密码'));
                }

                let pwd = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)/
                if (!pwd.test(value)) {
                    callback(new Error('密码需至少包含数字、字母、符号中的2种'));
                }
                if (value.length < 8) {
                    callback(new Error('密码长度应不低于8位'));
                }
                callback();
            };
            //二次密码验证
            let checkPwd2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== that.login_data.pwd) {
                    callback(new Error('两次输入密码不一致!'));
                }
                callback();
            };

            return {
                wxShow: false,
                //登录报错信息
                loginError: false,
                loginErrorMessage: "",
                //登录表单
                login_data: {
                    name: "",
                    pwd: "",
                    rpwd: '',
                    id_card: '',
                    phone: '',
                },
                rules: {
                    name: [
                        {validator: checkName, trigger: 'blur'},
                    ],
                    phone: [
                        {validator: checkPhone, trigger: 'blur'},
                    ],
                    id_card: [
                        {validator: checkIDCard, trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '请选择考试赛道', trigger: 'change'}
                    ],
                    pwd: [
                        {validator: checkPwd, trigger: 'blur'},
                    ],
                    rpwd: [
                        {validator: checkPwd2, trigger: 'blur'},
                    ],
                }
            };
        },
        components: {},
        mounted() {
            document.title = "陕西省首届密码技术竞赛"
            //设置背景动画播放速度
            let that = this
            // 获取 User Agent
            var userAgent = navigator.userAgent.toLowerCase();
            // 判断是否在微信中打开
            if (userAgent.indexOf('micromessenger') !== -1) {
                that.wxShow = true;
            } else {
                that.$message.error('请在微信中打开');
                that.wxShow = false;
            }
        },
        methods: {
            login(formName) {
                let that = this
                let login_data = that.login_data

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        request({
                            url: "/mn/reg",
                            data: login_data,
                        }).then((ret) => {
                            if (ret.code != 200) {
                                that.$message.error(ret.message);
                            } else {
                                that.$message.success(ret.message);
                                this.$router.push("/");
                            }
                        })

                    } else {
                        console.log('error submit!!');
                    }
                });
            },
        },
    };
</script>
